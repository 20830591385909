import { useContext } from 'react'

import ToggleComponentContext from '@providers/toggleComponentProvider'

export default function useToggleComp() {
  const { OverlayIsActive, toggleOverlay, customProps } = useContext(
    ToggleComponentContext
  )

  return { OverlayIsActive, toggleOverlay, customProps }
}
