module.exports = {
  '1/2': '50%',
  '2': '12px',

  '4': '20px',

  '7': '32px',
  '8': '40px',

  '-2': '-12px',
  '-4': '-20px',

  '-7': '-32px',
  '-9': '-52px',
}
