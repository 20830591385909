/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

const React = require('react')
const ReactIntl = require('react-intl')
const moment = require('moment')
const Layout = require('./../src/components/layout/index').default
const Metatags = require('./../src/objects/metatags/index').default

const { messages } = require('./../src/translations/index')
const { defaultLang } = require('./../src/translations/index')

const resolveConfig = require('tailwindcss/resolveConfig')
const twconfig = require('./../tailwind.config.js')
const { ThemeProvider } = require('styled-components')
const { ParallaxProvider } = require('react-scroll-parallax')

const { TextParserProvider } = require('../src/providers/textParserProvider')

const {
  createMuiTheme,
  ThemeProvider: MuiThemeProvider,
} = require('@material-ui/core/styles')

const { TrackingProvider } = require('../src/providers/trackingProvider')
const {
  ToggleComponentProvider,
} = require('../src/providers/toggleComponentProvider')

const fullTwconfig = resolveConfig(twconfig)
const twTheme = fullTwconfig.theme

const muitheme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiSvgIcon: {
      // Name of the rule
      root: {
        fontSize: null,
      },
      fontSizeSmall: {
        fontSize: null,
      },
      fontSizeLarge: {
        fontSize: null,
      },
    },
  },
  mapStyles: {
    cityMarkerColor: twTheme.colors.blue.default,
    actionMarkerColor: twTheme.colors.yellow.default,
    actionMarkerSelectedColor: twTheme.colors.red.default,
    actionMarkerCountColor: twTheme.colors.blue.default,
    popupHeaderSectionBGColor: twTheme.colors['gray-light']['650'],
    popupCopySectionBGColor: twTheme.colors['gray-light']['300'],
    popupSubtitleColor: twTheme.colors.black['650'],
  },
})

moment.locale('de', {
  relativeTime: {
    past: 'vor %s',
    h: '%d Stunde',
    hh: '%d Stunden',
    d: '%d Tag',
    dd: '%d Tagen',
  },
})

exports.wrapPage = ({ element, props }) => {
  return (
    <ReactIntl.IntlProvider
      locale={props.pageContext.locale || defaultLang}
      defaultLocale={defaultLang}
      messages={messages[props.pageContext.locale] || messages[defaultLang]}
    >
      <ParallaxProvider>
        <MuiThemeProvider theme={muitheme}>
          <ThemeProvider theme={twTheme}>
            <TrackingProvider {...props}>
              <TextParserProvider {...props}>
                <ToggleComponentProvider {...props}>
                  {props.pageContext?.page?.metaTags && (
                    <Metatags
                      locale={props.pageContext.locale}
                      meta={props.pageContext.page.metaTags}
                    />
                  )}
                  <Layout {...props}>{element}</Layout>
                </ToggleComponentProvider>
              </TextParserProvider>
            </TrackingProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </ParallaxProvider>
    </ReactIntl.IntlProvider>
  )
}
