import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Icon from '@objects/icon'

function renderSearchResultIcon(className, type) {
  switch (type) {
    case 'blog':
      return <Icon name="Description" className={className} />
    case 'newsroom':
      return <Icon name="Description" className={className} />
    case 'Pdf':
      return <Icon name="PDF" className={className} />
    case 'Media':
      return <Icon name="Media" className={className} />
    case 'Faq':
      return <Icon name="FAQ" className={className} />

    default:
      return false
  }
}

function SearchResultIcon({ className, type }) {
  return (
    <>{renderSearchResultIcon(clsx(className, 'result-type-icon'), type)}</>
  )
}

SearchResultIcon.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
}

export default SearchResultIcon
