import tw from 'twin.macro'

function getTextClasses(text) {
  switch (text) {
    case 'intro':
      return tw`text-2lg leading-7 md:text-3xl md:leading-10`
    case 'textbox':
      return tw`md:leading-5`
    case 'tagmd':
      return tw`text-blue font-medium text-base leading-3`
    case 'taglg':
      return tw`text-blue font-medium text-lg leading-5`
    case 'tagxl':
      return tw`text-blue font-medium text-lg leading-5 md:text-xl md:leading-7`
    case 'metamd':
      return tw`font-normal text-black-650 text-sm leading-3`
    case 'metalg':
      return tw`font-normal text-black-650 text-base leading-3`
    case 'metaxl':
      return tw`font-normal text-black-650 text-2lg leading-5 md:text-xl md:leading-7`
    case 'navigation':
      return tw`font-normal text-lg leading-5`
    default:
      return
  }
}

const text = (text) => {
  return getTextClasses(text)
}

export default text
