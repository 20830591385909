import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import Copy from '@objects/copy'
import Icon from '@objects/icon'
import ToggleComponentContext from '@providers/toggleComponentProvider'
import styled, { useTheme } from 'styled-components'
import breakpoint from '@utils/breakpoint'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useMediaQuery } from '@material-ui/core'

import Img from 'gatsby-image'

const StyledBackdrop = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.black[650]};
  opacity: 0.65;
`
const CustomOverlay = styled.div.attrs({
  className: 'bg-white p-8 relative rounded-vsm',
})`
  max-width: 100%;
  width: 100%;
  height: 100%;

  ${breakpoint('lg')`
      max-width: 79%;
      width: 79%;
      height: 50%;
    `}
`
const SlideContent = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
`

const MobileNavigation = styled.div`
  display: flex;
  justify-content: center;
  & div.swiperPrevBtnUnique {
    padding-right: 10px;
    ${breakpoint('lg')`
    position: absolute;
    left: 10px;
    top: 50%;
    `}
  }
  & div.swiperNextBtnUnique {
    padding-left: 10px;
    ${breakpoint('lg')`
    position: absolute;
    right: 10px;
    top: 50%;
    `}
  }
  & div.swiper-button-disabled {
    color: lightgrey;
  }
`

function SliderOverlay({ slidesData, initialSlide, onAcceptance }) {
  const { toggleOverlay } = useContext(ToggleComponentContext)
  const swiperPrevBtnRef = useRef(null)
  const swiperNextBtnRef = useRef(null)
  const { colors } = useTheme()
  const isMobile = useMediaQuery('(max-width: 1024px)')

  function renderNavigation() {
    return (
      <MobileNavigation>
        <div ref={swiperPrevBtnRef} className="swiperPrevBtnUnique">
          <Icon name="Prev" />
        </div>
        <div ref={swiperNextBtnRef} className="swiperNextBtnUnique">
          <Icon name="Next" />
        </div>
      </MobileNavigation>
    )
  }

  function renderSlides() {
    return [].concat(
      slidesData.map((item, index) => (
        <SwiperSlide key={`slide-item-${index}`}>
          <SlideContent>
            <Img
              className="w-full"
              fluid={{
                ...item.image.fluid,
                media: `(min-width: 0px)`,
              }}
              backgroundColor={true}
              imgStyle={{ objectPosition: 'top center', objectFit: 'contain' }}
              alt={item.alt}
              loading="eager"
            />
            <Copy>{item.copy}</Copy>
          </SlideContent>
        </SwiperSlide>
      ))
    )
  }

  function onClose() {
    onAcceptance()
    toggleOverlay(false)
  }

  return (
    <>
      <StyledBackdrop
        className="absolute top-0 bottom-0 left-0 right-0 "
        onClick={onClose}
      />
      <CustomOverlay>
        <button
          data-testid="close-button"
          className={'absolute top-2 right-2 w-5 h-5 text-red'}
          onClick={onClose}
        >
          <Icon className="text-xl align-top" name="Close" />
        </button>
        <Swiper
          //navigation={!isMobile ? true : navigationObject}
          //navigation={navigationObject}
          navigation={{
            prevEl: '.swiperPrevBtnUnique',
            nextEl: '.swiperNextBtnUnique',
          }}
          style={{
            height: '100%',
            '--swiper-navigation-color': colors.blue.default,
            '--swiper-navigation-size': 55,
          }}
          spaceBetween={10}
          slidesPerView={1}
          watchOverflow={true}
          modules={[Navigation]}
          initialSlide={initialSlide}
        >
          {renderSlides()}

          {/* <div ref={swiperPrevBtnRef}>
            <Icon name="Close" />
          </div>
          <div ref={swiperNextBtnRef}>
            <Icon name="Next" />
          </div> */}
        </Swiper>
        {renderNavigation()}
      </CustomOverlay>
    </>
  )
}

SliderOverlay.propTypes = {
  slidesData: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object.isRequired,
      copy: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
  initialSlide: PropTypes.number,
  onAcceptance: PropTypes.func,
}

export default SliderOverlay
