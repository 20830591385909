import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import Icon from '@objects/icon'

const StyledArrowForward = styled(Icon).attrs({
  name: 'TextArrow',
  className: 'text-xl text-blue',
})`
  margin: 0 3px;
`

function BreadCrumbs({ className, crumbList }) {
  const intl = useIntl()

  function renderCrumbs() {
    return crumbList.map((crumb, i) => {
      if (crumb.link) {
        return (
          <li className="flex items-center" key={`${crumb.link}${i}`}>
            <Link
              to={crumb.link}
              data-testid={'crumblink'}
              className="font-medium leading-3 self-end"
            >
              {crumb.label}
            </Link>
            <StyledArrowForward />
          </li>
        )
      } else {
        return (
          <li
            key={`${crumb.label}${i}`}
            data-testid={'crumblabel'}
            className="font-normal leading-3 self-end"
          >
            {crumb.label}
          </li>
        )
      }
    })
  }
  return (
    <nav aria-label={intl.formatMessage({ id: 'navigation.breadcrumb.label' })}>
      <ol className="hidden md:flex flex-row items-center flex-no-wrap mt-8 mb-7 leading-5">
        <li>
          <Link to="/">
            <Icon
              name="Home"
              className="text-xl"
              aria-label={intl.formatMessage({
                id: 'navigation.breadcrumb.home',
              })}
              aria-hidden={false}
            />
          </Link>
          <StyledArrowForward />
        </li>
        {renderCrumbs()}
      </ol>
    </nav>
  )
}

BreadCrumbs.propTypes = {
  className: PropTypes.string,
  crumbList: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default BreadCrumbs
