import React, { useState } from 'react'
import PropTypes from 'prop-types'

const GlossaryScopeContext = React.createContext({})

export function GlossaryScopeProvider({ children }) {
  const [foundEntries, setFoundEntries] = useState([])

  function updateFoundEntries(entries) {
    setFoundEntries((state) => {
      return [...state, ...entries]
    })
  }

  return (
    <GlossaryScopeContext.Provider value={{ foundEntries, updateFoundEntries }}>
      {children}
    </GlossaryScopeContext.Provider>
  )
}

GlossaryScopeProvider.propTypes = {
  children: PropTypes.any,
}

export default GlossaryScopeContext
