import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'

import Img from 'gatsby-image'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  logoGrid: {
    width: 'auto',
    height: '50%',
  },
  logoItemXs: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logoItemMd: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}))

const logos = [
  {
    xs: 4,
    sm: 3,
    md: 2,
    idName: 'DeutscheBahn',
    widthXs: '40px',
    widthMd: '80px',
    style: {},
    styleXs: { marginRight: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 2,
    idName: 'APS-Logo-blau',
    widthXs: '90px',
    widthMd: '150px',
    style: { margin: 'auto' },
    styleXs: { marginRight: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 2,
    idName: '1600px-Flixtrain_Logo',
    widthXs: '120px',
    widthMd: '250px',
    style: {},
    styleXs: { marginLeft: 'auto' },
    griditemStyle: { flexBasis: 'unset', maxWidth: '200%' },
  },
  {
    xs: 4,
    sm: 3,
    md: 2,
    idName: 'Logo_BSN',
    widthXs: '75px',
    widthMd: '150px',
    style: { margin: 'auto' },
    styleXs: { marginRight: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 2,
    idName: 'GDL',
    widthXs: '80px',
    widthMd: '150px',
    style: { marginLeft: 'auto' },
    styleXs: { margin: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 3,
    idName: 'Fahrgastverband-PRO-BAHN',
    widthXs: '80px',
    widthMd: '150px',
    style: { marginRight: 'auto' },
    styleXs: { marginLeft: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 3,
    idName: 'VCD',
    widthXs: '70px',
    widthMd: '150px',
    style: { margin: 'auto' },
    styleXs: { marginRight: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 3,
    idName: 'EVG',
    widthXs: '60px',
    widthMd: '100px',
    style: { margin: 'auto' },
    styleXs: { margin: 'auto' },
    griditemStyle: {},
  },

  {
    xs: 4,
    sm: 3,
    md: 3,
    idName: 'DieGueterbahnen-Logo',
    widthXs: '100px',
    widthMd: '150px',
    style: { marginLeft: 'auto' },
    styleXs: { marginLeft: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 2,
    idName: 'Alstom.svg',
    widthXs: '100px',
    widthMd: '150px',
    style: { marginRight: 'auto' },
    styleXs: { marginRight: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 2,
    idName: 'Bombardier_Logo',
    widthXs: '80px',
    widthMd: '150px',
    style: { margin: 'auto' },
    styleXs: { margin: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 2,
    idName: 'siemens-logo-default',
    widthXs: '80px',
    widthMd: '150px',
    style: { margin: 'auto' },
    styleXs: { marginLeft: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 3,
    sm: 3,
    md: 2,
    idName: 'bgl_logo',
    widthXs: '28px',
    widthMd: '50px',
    style: { margin: 'auto' },
    styleXs: { marginRight: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 3,
    sm: 3,
    md: 2,
    idName: '2560px-BDI.svg',
    widthXs: '50px',
    widthMd: '150px',
    style: { margin: 'auto' },
    styleXs: { margin: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 3,
    sm: 3,
    md: 2,
    idName: 'bvmb',
    widthXs: '80px',
    widthMd: '150px',
    style: { marginLeft: 'auto' },
    styleXs: { margin: 'auto' },
    griditemStyle: {},
  },

  {
    xs: 3,
    sm: 3,
    md: 3,
    idName: 'dslv-logo',
    widthXs: '100px',
    widthMd: '150px',
    style: { marginRight: 'auto' },
    styleXs: { marginLeft: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 3,
    idName: 'Logo-Bauindustrie-weiss',
    widthXs: '100px',
    widthMd: '200px',
    style: { margin: 'auto' },
    styleXs: { marginRight: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 3,
    idName: 'logo-mofair',
    widthXs: '60px',
    widthMd: '150px',
    style: { margin: 'auto' },
    styleXs: { margin: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 3,
    idName: '1280px-Verband_der_Bahnindustrie_logo.svg',
    widthXs: '150px',
    widthMd: '150px',
    style: { marginLeft: 'auto' },
    styleXs: { marginLeft: 'auto' },
    griditemStyle: {},
  },

  {
    xs: 3,
    sm: 3,
    md: 1,
    idName: 'VDV',
    widthXs: '150px',
    widthMd: '250px',
    style: { margin: 'auto' },
    styleXs: { marginRight: 'auto' },
    griditemStyle: { flexBasis: 'unset', maxWidth: '200px' },
  },
  {
    xs: 3,
    sm: 3,
    md: 1,
    idName: 'zdb-logo',
    widthXs: '30px',
    widthMd: '100px',
    style: { margin: 'auto' },
    styleXs: { margin: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 3,
    sm: 3,
    md: 1,
    idName: 'VPI',
    widthXs: '50px',
    widthMd: '100px',
    style: { margin: 'auto' },
    styleXs: { margin: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 3,
    sm: 3,
    md: 1,
    idName: 'ZVEI-Logo',
    widthXs: '50px',
    widthMd: '100px',
    style: { margin: 'auto' },
    styleXs: { marginLeft: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 1,
    idName: 'dvf_logo_01',
    widthXs: '50px',
    widthMd: '100px',
    style: { margin: 'auto' },
    styleXs: { marginRight: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 1,
    idName: '2000px-IG_Metall_logo.svg',
    widthXs: '30%',
    widthMd: '60%',
    style: { margin: 'auto' },
    styleXs: { margin: 'auto' },
    griditemStyle: {},
  },
  {
    xs: 4,
    sm: 3,
    md: 1,
    idName: 'Deutscher-Bahnkunden-Verband',
    widthXs: '35px',
    widthMd: '100px',
    style: { marginLeft: 'auto' },
    styleXs: { marginLeft: 'auto' },
    griditemStyle: {},
  },
]

const logosMap = logos.map((item) => item)

function LogoGrid() {
  const classes = useStyles()

  const { footerLogos } = useStaticQuery(graphql`
    query {
      footerLogos: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "footer/logos" }
        }
      ) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 250, quality: 80) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.logoItemXs}
      >
        {logosMap.map((item) => (
          <Grid item xs={item.xs} sm={item.sm} md={item.md} key={item.idName}>
            <Img
              style={{ ...item.styleXs, width: item.widthXs, maxWidth: '100%' }}
              imgStyle={{ height: 'auto' }}
              alt={`Logo - ${item.idName}`}
              fluid={{
                ...footerLogos.nodes?.find((node) => node.name === item.idName)
                  ?.childImageSharp?.fluid,
                media: `(min-width: 0px)`,
              }}
              backgroundColor="grey"
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.logoItemMd}
        role="group"
        aria-label="Logos"
      >
        {logosMap.map((item) => (
          <Grid
            item
            xs={item.xs}
            sm={item.sm}
            md={item.md}
            key={item.idName}
            style={item.griditemStyle}
            role="listitem"
          >
            <Img
              style={{ ...item.style, width: item.widthMd, maxWidth: '100%' }}
              imgStyle={{ height: 'auto' }}
              alt={`Logo - ${item.idName}`}
              fluid={{
                ...footerLogos.nodes?.find((node) => node.name === item.idName)
                  ?.childImageSharp?.fluid,
                media: `(min-width: 0px)`,
              }}
              backgroundColor="grey"
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

LogoGrid.propTypes = {
  className: PropTypes.string,
  level: PropTypes.number,
  id: PropTypes.string,
  children: PropTypes.any,
  html: PropTypes.string,
}

export default LogoGrid
