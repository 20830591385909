const Height = require('./height')
const Screens = require('./screens')
const Container = require('./container')
const Spacings = require('./spacings')
const Width = require('./width')
const Colors = require('./colors')
const LineHeight = require('./lineHeight')
const FontSize = require('./fontSize')
const FontFamily = require('./fontFamily')
const BorderRadius = require('./borderRadius')
const BoxShadow = require('./boxShadow')
const Opacity = require('./opacity')
const Inset = require('./inset')
const MaxWidth = require('./maxWidth')
const TransitionProperty = require('./transitionProperty')

module.exports = {
  screens: Screens,
  container: Container,
  lineHeight: LineHeight,
  fontSize: FontSize,
  extend: {
    colors: Colors,
    borderRadius: BorderRadius,
    boxShadow: BoxShadow,
    spacing: Spacings,
    width: Width,
    height: Height,
    fontFamily: FontFamily,
    opacity: Opacity,
    inset: Inset,
    maxWidth: MaxWidth,
    transitionProperty: TransitionProperty,
  },
}
