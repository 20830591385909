const { width } = require('tailwindcss/defaultTheme')

module.exports = {
	...width,
	'1/7': '14.2857143%',
	'2/7': '28.5714286%',
	'3/7': '42.8571429%',
	'4/7': '57.1428571%',
	'5/7': '71.4285714%',
	'6/7': '85.7142857%',
	'1/8': '12.5%',
	'2/8': '25%',
	'3/8': '37.5%',
	'4/8': '50%',
	'5/8': '62.5%',
	'6/8': '75%',
	'7/8': '87.5%',
	'1/10': '10%',
	'2/10': '20%',
	'3/10': '30%',
	'4/10': '40%',
	'5/10': '50%',
	'6/10': '60%',
	'7/10': '70%',
	'8/10': '80%',
	'9/10': '90%',
}
