import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Trunc from 'truncate-html'
import styled from 'styled-components'
import tw from 'twin.macro'
import parse, { domToReact } from 'html-react-parser'

import { GlossaryScopeProvider } from '@providers/glossaryScopeProvider'
import { useTextParser, useGlossaryScope } from '@hooks'

import text from '@utils/text'
import Icon from '@objects/icon'
import UnorderedList from '@objects/unorderedlist'
import OrderedList from '@objects/orderedlist'
import Image from '@objects/image'

const StyledAnchorlink = styled.a.attrs({
  className: 'text-red font-medium inline-flex flex-row items-center relative',
})``

const StyledArrow = styled(Icon).attrs({
  name: 'TextArrow',
  className: 'text-red text-xl',
})`
  margin-left: 2px;
`

function Copy({
  className,
  children,
  html,
  div,
  span,
  label,
  truncate,
  truncateextend,
  noHrefArrow,
  parseGlossary,
  parseGlossaryCopy,
  glossaryExcludes,
  glossaryType,
  ...props
}) {
  const { parseText, glossaryInitialized } = useTextParser()
  const { foundEntries, updateFoundEntries } = useGlossaryScope()
  const [parsedChildren, setParsedChildren] = useState(children)

  useEffect(() => {
    if (
      (children && parseGlossary && glossaryInitialized) ||
      (children && parseGlossaryCopy && glossaryInitialized)
    ) {
      const parsed = parseText(
        children,
        glossaryExcludes,
        glossaryType,
        foundEntries || []
      )
      updateFoundEntries && updateFoundEntries(parsed.foundEntries)
      setParsedChildren(parsed.text)
    }
  }, [glossaryInitialized])

  function Wrapper({ children }) {
    if (div) return <div className={className}>{children}</div>
    if (span) return <span className={className}>{children}</span>
    if (label)
      return (
        <label className={className} {...props}>
          {children}
        </label>
      )
    return <p className={className}>{children}</p>
  }

  function TruncateOrNot(text) {
    if (truncate) {
      return Trunc(text, truncate, { byWords: true })
    } else {
      return text
    }
  }
  function renderCopy() {
    if (html) {
      const htmlWithBr = html.replace(/(?:\r\n|\r|\n)/g, '<br>')
      let containsBlockElements = false
      const renderedHTML = parse(TruncateOrNot(htmlWithBr), {
        replace: function (domNode) {
          if (domNode.type === 'tag') {
            if (domNode.name === 'a') {
              if (domNode.attribs.href === '#usercentrics-revoke') {
                return (
                  <StyledAnchorlink
                    {...domNode.attribs}
                    onClick={() => window.UC_UI.showSecondLayer()}
                    role="button"
                    aria-label={domToReact(domNode.children)}
                  >
                    {domToReact(domNode.children)}
                  </StyledAnchorlink>
                )
              }
              return (
                <StyledAnchorlink
                  {...domNode.attribs}
                  role="button"
                  aria-label={domToReact(domNode.children)}
                >
                  {domToReact(domNode.children)}
                  {!noHrefArrow && <StyledArrow />}
                </StyledAnchorlink>
              )
            } else if (domNode.name === 'ul') {
              containsBlockElements = true

              return (
                <UnorderedList
                  parseGlossary={parseGlossary}
                  glossaryExcludes={glossaryExcludes}
                  glossaryType={glossaryType}
                  {...domNode.attribs}
                >
                  {domToReact(
                    domNode.children.filter((child) => child.name !== 'br')
                  ).map?.((child) => child.props.children)}
                </UnorderedList>
              )
            } else if (domNode.name === 'ol') {
              containsBlockElements = true
              return (
                <OrderedList
                  parseGlossary={parseGlossary}
                  glossaryExcludes={glossaryExcludes}
                  glossaryType={glossaryType}
                  {...domNode.attribs}
                >
                  {domToReact(
                    domNode.children.filter((child) => child.name !== 'br')
                  ).map?.((child) => child.props.children)}
                </OrderedList>
              )
            } else if (domNode.name === 'br') {
              if (
                domNode.parent === null ||
                ['ul', 'ol'].includes(domNode.parent.name)
              ) {
                return <Fragment />
              }
            } else if (domNode.name === 'p') {
              containsBlockElements = true
              if (domNode.children[0].name === 'img') {
                const img = domNode.children[0].attribs
                return (
                  <Image
                    plain
                    alt={img.alt}
                    image={{
                      src: img.src,
                    }}
                  />
                )
              }
            } else if (['h1', 'h2', 'h3', 'h4', 'h5'].includes(domNode.name)) {
              containsBlockElements = true
            }
          } else if (
            (domNode.type === 'text' && parseGlossary) ||
            (domNode.parent?.name === 'p' && parseGlossaryCopy)
          ) {
            const parsed = parseText(
              domNode.data,
              glossaryExcludes,
              glossaryType,
              foundEntries
            )
            updateFoundEntries && updateFoundEntries(parsed.foundEntries)
            return <Fragment role="paragraph">{parsed.text}</Fragment>
          }
        },
      })
      if (div && !containsBlockElements) {
        return (
          <p role="paragraph">
            {renderedHTML}
            {truncateextend}
          </p>
        )
      } else {
        return (
          <>
            {renderedHTML}
            {truncateextend}
          </>
        )
      }
    } else {
      return (
        <>
          {TruncateOrNot(parsedChildren)}
          {truncateextend}
        </>
      )
    }
  }

  return (
    <Wrapper>
      <GlossaryScopeProvider>{renderCopy()}</GlossaryScopeProvider>
    </Wrapper>
  )
}

Copy.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  html: PropTypes.string,
  div: PropTypes.bool,
  span: PropTypes.bool,
  label: PropTypes.bool,
  truncate: PropTypes.number,
  truncateextend: PropTypes.any,
  noHrefArrow: PropTypes.bool,
  parseGlossary: PropTypes.bool,
  parseGlossaryCopy: PropTypes.bool,
  glossaryExcludes: PropTypes.arrayOf(PropTypes.string),
  glossaryType: PropTypes.oneOf(['overlay', 'inline']),
}

Copy.defaultProps = {
  component: 'p',
  glossaryType: 'overlay',
  //parseGlossary: true,
}

const StyledCopy = styled(Copy)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    ${tw`
    mb-6
    `}
    &:not(:first-child) {
      ${tw`
      mt-9
      `}
    }
  }
  p {
    &:not(:last-child) {
      ${tw`
      mb-7
      `}
    }
  }
  br {
    ${tw`
    mb-3
    `}
  }
`

const IntroCopy = styled(StyledCopy)`
  p:first-child {
    ${text('intro')}
  }
`

const TeaserCopy = styled(StyledCopy)`
  ${text('textbox')}
`

export default StyledCopy
export { IntroCopy, TeaserCopy }
