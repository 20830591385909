import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styled from 'styled-components'
import tw from 'twin.macro'
import { FormattedMessage, useIntl } from 'react-intl'

import { useTracking, useToggleComp } from '@hooks'
import breakpoint from '@utils/breakpoint'
import Search from './types/search'
import Popup from './types/popup'
import Slider from './types/slider'
import Glossary from './types/glossary'
import Icon from '@objects/icon'

const StyledCloseButton = styled.button`
  ${tw`absolute cursor-pointer z-10 text-base font-medium text-red items-center`}
  cursor: pointer;
  right: ${({ theme }) => theme.spacing['3']};
  top: ${({ theme }) => theme.spacing['3']};
  ${breakpoint('md')`
    right: ${({ theme }) => theme.spacing['7']};
    top: ${({ theme }) => theme.spacing['7']};`}
`

function Overlay({ className }) {
  const overlayRef = useRef(null)
  const closeRef = useRef(null)
  const { pushMessage } = useTracking()
  const { OverlayIsActive, toggleOverlay, customProps } = useToggleComp()
  const [lastFocusable, setLastFocusable] = useState(null)
  const intl = useIntl()

  useEffect(() => {
    document.body.classList.remove('no-scroll')
    if (OverlayIsActive) {
      document.body.classList.add('no-scroll')

      pushMessage(`custom.overlay-show`, {
        message: customProps.trackingID,
      })

      const test = overlayRef.current?.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled]), [tabindex="1"]'
      )
      setLastFocusable(test[test.length - 1])
    } else {
      setLastFocusable(null)
      // eslint-disable-next-line no-unused-expressions
      customProps.trigger?.focus()
    }
  }, [OverlayIsActive])

  useEffect(() => {
    overlayRef.current.addEventListener('keydown', onKeyDown)

    return () => {
      overlayRef.current.removeEventListener('keydown', onKeyDown)
    }
  }, [lastFocusable])

  function onKeyDown(e) {
    const isTabPressed = e.key === 'Tab' || e.keyCode === 9
    const isEscapePressed = e.key === 'Escape' || e.keyCode === 27

    if (isEscapePressed) {
      toggleOverlay(false)
    } else if (!isTabPressed) {
      return
    }

    if (e.shiftKey) {
      /* shift + tab */ if (document.activeElement === closeRef.current) {
        lastFocusable.focus()
        e.preventDefault()
      }
    } /* tab */ else {
      if (document.activeElement === lastFocusable) {
        closeRef.current.focus()
        e.preventDefault()
      }
    }
  }

  function renderOverlayContent() {
    const props = {
      overlayRef: overlayRef,
      closeOverlay: () => toggleOverlay(false),
    }
    switch (customProps.type) {
      case 'search':
        return <Search index={customProps.index} {...props} />
      case 'popup':
        return (
          <Popup
            onAcceptance={customProps.onAcceptance}
            copy={customProps.copy}
            buttonlabel={customProps.buttonlabel}
          />
        )
      case 'slider':
        return (
          <Slider
            onAcceptance={customProps.onAcceptance}
            slidesData={customProps.slidesData}
            initialSlide={customProps.initialSlide}
            buttonlabel={customProps.buttonlabel}
          />
        )
      case 'glossary':
        return (
          <Glossary
            word={customProps.word}
            description={customProps.description}
            link={customProps.link}
            synonyms={customProps.synonyms}
          />
        )
      default:
        return <div>empty</div>
    }
  }

  return (
    <div
      ref={overlayRef}
      data-testid="overlay"
      className={clsx(className, {
        active: OverlayIsActive,
        popup: customProps.type === 'popup',
        slider: customProps.type === 'slider',
        glossary: customProps.type === 'glossary',
      })}
      role="dialog"
    >
      {customProps.type !== 'popup' &&
      customProps.type !== 'slider' &&
      customProps.type !== 'glossary' ? (
        <StyledCloseButton
          ref={closeRef}
          data-testid="close-button"
          onClick={() => toggleOverlay(false)}
          role="button"
          aria-label={intl.formatMessage({ id: 'overlay.close' })}
          type="button"
        >
          <FormattedMessage id="overlay.close" />
          <Icon name="Close" className="text-xl md:text-3xl" />
        </StyledCloseButton>
      ) : null}
      {renderOverlayContent()}
    </div>
  )
}

Overlay.propTypes = {
  className: PropTypes.string,
}

export default styled(Overlay).attrs({
  className:
    'bg-white fixed top-0 left-0 w-screen h-screen z-0 opacity-0 invisible transition-opacity ease-in-out duration-200 overflow-x-hidden overflow-y-auto',
})`
  &.active {
    z-index: 1002;
    ${tw`opacity-100 visible`}
  }
  &.slider,
  &.popup {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.glossary {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
