module.exports = {
  none: '1',
  '1': '18px',
  '2': '20px',
  '3': '22px',
  '4': '23px',
  '5': '24px',
  '6': '26px',
  '7': '28px',
  '8': '30px',
  '9': '34px',
  '10': '36px',
  '11': '40px',
  '12': '44px',
  '13': '50px',
  baseXS: '25px',
}
