import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Img from 'gatsby-image/withIEPolyfill'

function Image({ image, alt, description, copyright, className, plain }) {
  function renderImage() {
    if (plain) {
      return <img src={image.src} alt={alt} role="img" aria-label={alt} />
    } else {
      return (
        <Img
          alt={alt}
          fluid={{ ...image, media: `(min-width: 0px)` }}
          backgroundColor={true}
          role="img"
          aria-label={alt}
        />
      )
    }
  }

  return (
    <div
      className={clsx(className, 'mb-7')}
      role="img"
      aria-label={description}
    >
      {image && renderImage()}
      {(description || copyright) && (
        <div className="flex font-normal mt-2">
          {description && <div>{description}</div>}
          {copyright && <div className="text-sm ml-auto">{copyright}</div>}
        </div>
      )}
    </div>
  )
}

Image.propTypes = {
  image: PropTypes.object,
  alt: PropTypes.string,
  description: PropTypes.string,
  copyright: PropTypes.string,
  className: PropTypes.string,
  plain: PropTypes.bool,
}

export default Image
