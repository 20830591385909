import React, { useState } from 'react'
import PropTypes from 'prop-types'

const ToggleComponentContext = React.createContext()

export function ToggleComponentProvider({ children }) {
  const [OverlayIsActive, setOverlayIsActive] = useState(false)
  const [customProps, setCustomProps] = useState({})

  function toggleOverlay(show, type, props) {
    setOverlayIsActive(show)
    setCustomProps({
      type: type,
      ...props,
    })
  }

  return (
    <ToggleComponentContext.Provider
      value={{ OverlayIsActive, toggleOverlay, customProps }}
    >
      {children}
    </ToggleComponentContext.Provider>
  )
}

ToggleComponentProvider.propTypes = {
  children: PropTypes.any,
}

export default ToggleComponentContext
