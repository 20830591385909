const { spacings } = require('tailwindcss/defaultTheme')

module.exports = {
  ...spacings,
  none: '0',
  '0.5': '4px',
  '1': '8px',
  '2': '12px',
  '3': '16px',
  '4': '20px',
  '5': '24px',
  '6': '28px',
  '7': '32px',
  '8': '40px',
  '8.5': '52px',
  '9': '60px',
  '10': '80px',
  '11': '100px',
  '12': '116px',
  '13': '120px',
  '14': '124px',
  '15': '200px',

  '1/12': '8.333333%',
}
