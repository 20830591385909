import React from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import text from '@utils/text'

function Container({
  children,
  className,
  nopadding,
  content,
  width,
  ...props
}) {
  return (
    <div
      className={clsx(className, {
        'py-8 md:py-0': !nopadding,
        'content-container': content,
        'large-container': width === 'lg',
        'medium-container': width === 'md',
        'small-container': width === 'sm',
      })}
      {...props}
    >
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  nopadding: PropTypes.bool,
  content: PropTypes.bool,
  width: PropTypes.string,
}

export default styled(Container)`
  max-width: ${({ theme }) => theme.container.maxWidth};
  margin-left: auto;
  margin-right: auto;
  ${tw`px-3 w-full relative`}

  &.large-container {
    max-width: ${({ theme }) => theme.container['maxWidth.lg']};
  }

  &.medium-container {
    max-width: ${({ theme }) => theme.container['maxWidth.md']};
  }
  &.small-container {
    max-width: ${({ theme }) => theme.container['maxWidth.sm']};
  }

  &.content-container {
    max-width: calc(${({ theme }) => theme.container.maxWidth} * 8 / 12);

    /** Styles used to when wrapping contentful component */
    & > p:first-child {
      ${text('intro')}
    }
    & > p {
      ${tw`
        mb-6
        md:mb-7
      `}
    }

    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6 {
      ${tw`
        mb-4
        md:mb-5
      `}
    }

    & > ul,
    & > ol {
      ${tw`
        mb-6
       md:mb-7
      `}
    }
  }
`
