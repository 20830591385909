import { css } from 'styled-components'
import Screens from './../../config/theme/screens'

function getSizeFromBreakpoint(breakpoint) {
  if (Screens[breakpoint]) {
    return Screens[breakpoint]
  } else {
    console.error('No valid breakpoint')
    return '0'
  }
}

const breakpoint = (breakpoint) => (...args) => css`
  @media (min-width: ${getSizeFromBreakpoint(breakpoint)}) {
    ${css(...args)}
  }
`

export default breakpoint
