import React, { useContext, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import MetaTags from '@objects/metatags'
import PropTypes from 'prop-types'

import Header from '@components/header'
import Footer from '@components/footer'
import Button from '@objects/button'
import Overlay from '@objects/overlay'
import Container from '@objects/container'
import BreadCrumb from '@objects/breadcrumb'

import TextParserContext from '@providers/textParserProvider'

import {
  LogoJsonLd,
  SitelinksSearchBoxJsonLd,
  JsonLd,
} from 'gatsby-plugin-next-seo'

function Layout({ children, pageContext, location }) {
  const { updateGlossaryData } = useContext(TextParserContext)
  const {
    allContentfulPage,
    siteSearchIndex,
    allContentfulGlossaryEntry,
  } = useStaticQuery(graphql`
    query {
      siteSearchIndex {
        index
      }
      allContentfulPage {
        nodes {
          node_locale
          slug
          modules {
            key
            contentfulfields {
              ... on ContentfulInfoCard {
                __typename
                key
                internalTitle
                title
                copy {
                  childMarkdownRemark {
                    html
                  }
                }
                buttonLabel
                buttonLink
                image {
                  fluid(quality: 80, maxWidth: 1480) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                  description
                }
              }
              ... on ContentfulStaticField {
                __typename
                key
                value {
                  childMarkdownRemark {
                    html
                  }
                }
                link
                media {
                  fluid(quality: 80, maxWidth: 1480) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                  description
                  file {
                    contentType
                    fileName
                    url
                    details {
                      size
                    }
                  }
                }
              }
              ... on ContentfulFact {
                __typename
                key
                title
                copy {
                  childMarkdownRemark {
                    html
                  }
                }
                image {
                  fluid(quality: 80, maxWidth: 600) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                  description
                }
              }
              ... on ContentfulImage {
                __typename
                id
                createdAt
                image {
                  file {
                    url
                  }
                  fluid(quality: 80, maxWidth: 600) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                  description
                }
              }
            }
          }
        }
      }
      allContentfulGlossaryEntry {
        nodes {
          node_locale
          description {
            childMarkdownRemark {
              html
            }
          }
          title
          synonyms
        }
      }
    }
  `)

  function getStaticFields() {
    return allContentfulPage.nodes?.find(
      (config) =>
        config.slug === pageContext.slug &&
        config.node_locale === pageContext.locale
    )?.modules
  }

  useEffect(() => {
    updateGlossaryData(
      allContentfulGlossaryEntry.nodes.filter(
        (node) => node.node_locale === pageContext.locale
      )
    )
  }, [])

  /**
   * @description Generated Breadcrumblist.
   */
  const cumbList = [].concat(
    pageContext.breadcrumbs || [],
    pageContext.frontmatter?.breadcrumbs || [],
    CrumbItem(pageContext?.page?.title) || []
  )

  /**
   * @description Transforms label and link to object
   * @param {String} label
   * @param {String} [link=null] Optional link
   * @returns {{label: String, link: String}} Label and Link for the Breadcrumb
   */
  function CrumbItem(label, link = null) {
    let result = {
      label: label ?? '',
    }
    if (link) result.link = link
    return result
  }

  const excludedUrls = ['/']

  pageContext.modules = getStaticFields()

  return (
    <div>
      <MetaTags
        locale={pageContext.locale}
        meta={{ canonical: pageContext.canonical }}
      />

      <LogoJsonLd
        logo="https://www.deutschlandtakt.de/img/logo/dt_logo.svg"
        url={pageContext.canonical}
      />
      <SitelinksSearchBoxJsonLd
        url={pageContext.canonical}
        searchHandlerQueryStringUrl={`${location.origin}/suchergebnisse/#searchquery`}
      />
      <JsonLd
        json={{
          '@context': 'https://schema.org',
          '@type': 'GovernmentOrganization',
          legalName: 'Bundesministerium für Digitales und Verkehr',
          name: 'Deutschlandtakt',
          address: {
            streetAddress: 'Invalidenstraße 44',
            addressLocality: 'Berlin',
            addressRegion: 'BER',
            postalCode: '10115',
            addressCountry: 'DE',
          },
          contactPoint: [
            {
              telephone: '+4930183000',
              contactType: 'customer service',
              areaServed: 'DE',
              availableLanguage: ['German', 'English'],
            },
          ],
        }}
      />

      <Header searchIndex={siteSearchIndex?.index} />

      {pageContext.slug && !excludedUrls.includes(pageContext.slug) && (
        <Container nopadding role="region">
          <BreadCrumb crumbList={cumbList} />
        </Container>
      )}

      {children}
      <Overlay />
      <Container nopadding role="region">
        <Button className="btn btn-backtop" backtop />
      </Container>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
  pageContext: PropTypes.object,
  location: PropTypes.object,
}

export default Layout
