import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  BurgerIcon,
  BurgerLine,
  MobileNavWrapper,
  MobileNavOverlay,
  MobileNavOffCanvas,
  MobileNavContent,
  Top,
  Bottom,
  MobileNavItem,
  SvgBg,
  Copyright,
} from './styled/styles'

function Burger({ className, active, onClick }) {
  const intl = useIntl()
  const activeClass = 'text-red'
  return (
    <div className={clsx(className, { active: active })} data-testid="burger">
      <BurgerIcon active={active}>
        <BurgerLine />
        <BurgerLine />
        <BurgerLine />
      </BurgerIcon>
      <MobileNavWrapper active={active}>
        <MobileNavOverlay active={active} />
        <MobileNavOffCanvas active={active}>
          <SvgBg>
            <img src="/img/tracklines/backgrounds/Randlinie3.svg" alt="" />
          </SvgBg>
          <MobileNavContent data-testid="navigation">
            <Top>
              <MobileNavItem
                to={intl.formatMessage({ id: 'path.vision' })}
                onClick={onClick}
                activeClassName={activeClass}
              >
                <FormattedMessage id="navigation.vision" />
              </MobileNavItem>
              <MobileNavItem
                to={intl.formatMessage({ id: 'path.concept' })}
                onClick={onClick}
                activeClassName={activeClass}
              >
                <FormattedMessage id="navigation.concept" />
              </MobileNavItem>
              <MobileNavItem
                to={intl.formatMessage({ id: 'path.implementation' })}
                onClick={onClick}
                activeClassName={activeClass}
              >
                <FormattedMessage id="navigation.implementation" />
              </MobileNavItem>
              <MobileNavItem
                to={intl.formatMessage({ id: 'path.facts' })}
                onClick={onClick}
                activeClassName={activeClass}
              >
                <FormattedMessage id="navigation.facts" />
              </MobileNavItem>
              <MobileNavItem
                to={intl.formatMessage({ id: 'path.blog' })}
                onClick={onClick}
                activeClassName={activeClass}
              >
                <FormattedMessage id="navigation.blog" />
              </MobileNavItem>
              <MobileNavItem
                to={intl.formatMessage({ id: 'path.newsroom' })}
                onClick={onClick}
                activeClassName={activeClass}
              >
                <FormattedMessage id="navigation.newsroom" />
              </MobileNavItem>
              <MobileNavItem
                to={intl.formatMessage({ id: 'path.downloads' })}
                onClick={onClick}
                activeClassName={activeClass}
              >
                <FormattedMessage id="navigation.newsroom.downloads" />
              </MobileNavItem>
              <MobileNavItem
                to={intl.formatMessage({ id: 'path.media' })}
                onClick={onClick}
                activeClassName={activeClass}
              >
                <FormattedMessage id="navigation.newsroom.media" />
              </MobileNavItem>
              <MobileNavItem
                to={intl.formatMessage({ id: 'path.faq' })}
                onClick={onClick}
                activeClassName={activeClass}
              >
                <FormattedMessage id="navigation.newsroom.faq" />
              </MobileNavItem>
            </Top>
            <Bottom>
              <Copyright
                html={intl.formatMessage({ id: 'footer.copyright' })}
              />
            </Bottom>
          </MobileNavContent>
        </MobileNavOffCanvas>
      </MobileNavWrapper>
    </div>
  )
}

Burger.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

export default Burger
