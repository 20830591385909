import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { Link } from 'gatsby'

import { Tooltip } from '@material-ui/core'

import ToggleComponentContext from '@providers/toggleComponentProvider'

import ChevronRight from '@material-ui/icons/ArrowForwardIosRounded'

function GlossaryLink({
  children,
  className,
  description,
  word,
  title,
  synonyms,
  type,
  ...props
}) {
  const intl = useIntl()
  const { toggleOverlay } = useContext(ToggleComponentContext)

  function openDescription() {
    toggleOverlay(true, 'glossary', {
      trackingID: 'glossary',
      width: 'md',
      word: title,
      description: description,
      synonyms: synonyms,
      link: `${intl.formatMessage({
        id: 'glossary.path',
      })}?#glossaryitem=${word}`,
    })
  }

  return (
    <Tooltip title={intl.formatMessage({ id: 'glossary.tooltip' })}>
      {type === 'inline' ? (
        <Link
          data-track-content
          data-tracking-id={`${intl.formatMessage({
            id: 'glossary.path',
          })}?#glossaryitem=${word}`}
          className={clsx(className, 'font-bold focus:underline')}
          to={`${intl.formatMessage({
            id: 'glossary.path',
          })}?#glossaryitem=${word}`}
          {...props}
        >
          <ChevronRight
            className="-ml-0.5 text-red-600"
            style={{
              width: '1em',
              height: '1em',
              marginLeft: '2px',
              verticalAlign: 0,
              fontSize: '0.7em',
            }}
          />
          {children}
        </Link>
      ) : (
        <button
          data-track-content
          data-tracking-id={`${intl.formatMessage({
            id: 'glossary.path',
          })}?#glossaryitem=${word}`}
          className={clsx(
            className,
            'font-bold focus:underline',
            'inline-block'
          )}
          onClick={openDescription}
          {...props}
        >
          <ChevronRight
            className="-ml-0.5 text-red-600"
            style={{
              width: '1em',
              height: '1em',
              marginLeft: '2px',
              verticalAlign: 0,
              fontSize: '0.7em',
            }}
          />
          {children}
        </button>
      )}
    </Tooltip>
  )
}

GlossaryLink.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  description: PropTypes.string,
  word: PropTypes.string,
  title: PropTypes.string,
  synonyms: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.oneOf(['overlay', 'inline']),
}

GlossaryLink.defaultProps = {
  type: 'overlay',
}

export default GlossaryLink
