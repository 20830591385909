import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import breakpoint from '@utils/breakpoint'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { throttle } from 'lodash'

import Search from '@components/search'
import Burger from '@objects/burger'
import Navigation from '@components/navigation'
import Container from '@objects/container'

const HeaderContainer = styled(Container)`
  ${tw`flex justify-between items-center py-0`}
`

const NavigationWrapper = styled.div.attrs({
  className: 'hidden',
})`
  ${breakpoint('lg')`
    ${tw`flex`}
  `}
`

const LeftLogo = styled(Link)`
  img {
    height: 40px;
  }
  ${breakpoint('md')`
    img {
      height: auto;
    }
  `}
`

const BurgerWrapper = styled.div.attrs({
  className: 'block',
})`
  ${breakpoint('lg')`
    ${tw`hidden`}
  `}
`

const RightItems = styled.div.attrs({
  className: 'flex space-x-2',
})``

function Header({ className, searchIndex }) {
  const [mobileOpen, setMobileOpen] = useState(false)

  useEffect(() => {
    Array.from(document.querySelectorAll('a[href^="#"]')).forEach((anchor) => {
      anchor.addEventListener('click', (ev) => {
        ev.preventDefault()
        const anchorelement = document.querySelector(
          `[id="${anchor.getAttribute('href').substring(1)}"]`
        )
        window.scrollTo({
          top: anchorelement.offsetTop,
          behavior: 'smooth',
        })
      })
    }, [])
    const resizeHandler = (ev) => {
      setMobileOpen(false)
      document.body.classList.remove('hide-dt-menu')
      document.body.classList.add('show-dt-menu')
      if (window.innerWidth >= 1024) {
        document.body.style.paddingTop = '116px'
      } else {
        document.body.style.paddingTop = '0'
      }
    }

    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  // scroll handler for desktop show/hide header
  useEffect(() => {
    let lastScrollPos = window.pageYOffset
    document.body.classList.add('show-dt-menu')
    window.innerWidth >= 1024 && (document.body.style.paddingTop = '116px')
    const throttledScrollHandler = throttle((e) => {
      var st = window.pageYOffset || document.documentElement.scrollTop // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (window.innerWidth < 1024) {
        // theme/screens lg
        lastScrollPos = st
        return
      }
      if (st > lastScrollPos && st > 116) {
        // scroll down
        document.body.classList.remove('show-dt-menu')
        document.body.classList.add('hide-dt-menu')
      } else if (st < lastScrollPos) {
        document.body.classList.remove('hide-dt-menu')
        document.body.classList.add('show-dt-menu')
      }
      lastScrollPos = st
    }, 250)

    window.addEventListener('scroll', throttledScrollHandler)

    return () => {
      window.removeEventListener('scroll', throttledScrollHandler)
    }
  }, [])

  function mobilgeToggle() {
    setMobileOpen(!mobileOpen)
    document.body.classList.remove('no-scroll')
    if (!mobileOpen) {
      document.body.classList.add('no-scroll')
    }
  }

  return (
    <div className={className} role="banner">
      <HeaderContainer>
        <LeftLogo to="/" onClick={mobileOpen ? mobilgeToggle : null}>
          <img src="/img/logo/dt_logo.svg" alt="Deutschlandtakt" role="img" />
        </LeftLogo>
        <NavigationWrapper>
          <Navigation onClick={mobileOpen ? mobilgeToggle : null} />
        </NavigationWrapper>
        <RightItems>
          <Search searchIndex={searchIndex} />
          <BurgerWrapper onClick={mobilgeToggle}>
            <Burger active={mobileOpen} />
          </BurgerWrapper>
        </RightItems>
      </HeaderContainer>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  searchIndex: PropTypes.any,
}

export default styled(Header).attrs({
  className: 'shadow py-2 lg:py-7',
})`
  width: 100%;
  ${breakpoint('md')`
      position: fixed;
      top: 0;
      z-index: 1000;
      background: white;
      transition: transform 200ms ease  `}

  .hide-dt-menu & {
    transform: translateY(-100%);
  }
`
