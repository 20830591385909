const { wrapPage } = require('./gatsby-functions/pageWrap')

require('./src/tailwind.css')
require('leaflet/dist/leaflet.css')
require('swiper/css')
require('swiper/css/navigation')
require('react-leaflet-markercluster/dist/styles.min.css')

exports.wrapPageElement = wrapPage

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  return location.state && location.state.preventScroll ? false : true
}
