import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'twin.macro'
import Icon from '@objects/icon'

const StyledAnchorlink = styled.a.attrs({
  className: 'text-red font-medium inline-flex flex-row items-center relative',
})``

const StyledArrowForward = styled(Icon).attrs({
  name: 'TextArrow',
  className: 'text-red text-xl',
})`
  margin-left: 2px;
`

const StyledContentWrapper = styled.div`
  ul {
    ${tw`
      pl-3
      list-disc
    `}
  }
  ol {
    ${tw`
      pl-3
      list-decimal
    `}
  }
`

function ListContent({ children }) {
  function renderChildren() {
    if (children instanceof Array) {
      return children.map((child, i) => {
        if (child?.type === 'a') {
          return (
            <StyledAnchorlink {...child.props} key={`item-${i}`}>
              {child.props.children}
              <StyledArrowForward />
            </StyledAnchorlink>
          )
        }
        return <Fragment key={`item-${i}`}>{child}</Fragment>
      })
    } else {
      return children
    }
  }

  return <StyledContentWrapper>{renderChildren()}</StyledContentWrapper>
}

ListContent.propTypes = {
  children: PropTypes.any,
}

export default ListContent
