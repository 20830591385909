import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import styled from 'styled-components'

import ToggleComponentContext from '@providers/toggleComponentProvider'
import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Button from '@objects/button'
import Icon from '@objects/icon'

const StyledBackdrop = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.black[650]};
  opacity: 0.65;
`

function Glossary({ word, description, link, synonyms }) {
  const { toggleOverlay } = useContext(ToggleComponentContext)

  function onClose() {
    toggleOverlay(false)
  }

  return (
    <>
      <StyledBackdrop
        className="absolute top-0 bottom-0 left-0 right-0 "
        onClick={onClose}
        role="dialog"
      />

      <div className="bg-white p-8 relative max-w-704 rounded-vsm">
        <button
          data-testid="close-button"
          className={'absolute top-2 right-2 w-5 h-5'}
          onClick={onClose}
        >
          <Icon className="text-xl align-top" name="Close" />
        </button>
        <Headline id="dialog-title" level={5} className="mb-4" ariaLabel={word}>
          {word}
        </Headline>
        <Copy
          className="mb-5"
          html={description}
          type="faq"
          component="div"
          parseGlossary
          glossaryExcludes={synonyms}
        />
        <Button type="text" to={link} onClick={() => toggleOverlay(false)}>
          <FormattedMessage id="glossary.popup.link" />
        </Button>
      </div>
    </>
  )
}

Glossary.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  word: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  synonyms: PropTypes.arrayOf(PropTypes.string),
}

export default Glossary
