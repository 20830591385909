import React from 'react'
import PropTypes from 'prop-types'

function Headline({ className, level, children, id, html, ariaLabel }) {
  function renderChildren() {
    if (html) return null
    return children
  }
  function Level() {
    const Props = {
      id: id,
      className: className,
      ...(html && {
        dangerouslySetInnerHTML: {
          __html: html,
        },
      }),
    }
    switch (level) {
      case 1:
        return (
          <h1 {...Props} aria-label={ariaLabel}>
            {renderChildren()}
          </h1>
        )
      case 3:
        return (
          <h3 {...Props} aria-label={ariaLabel}>
            {renderChildren()}
          </h3>
        )
      case 4:
        return (
          <h4 {...Props} aria-label={ariaLabel}>
            {renderChildren()}
          </h4>
        )
      case 5:
        return (
          <h5 {...Props} aria-label={ariaLabel}>
            {renderChildren()}
          </h5>
        )
      default:
        return (
          <h2 {...Props} aria-label={ariaLabel}>
            {renderChildren()}
          </h2>
        )
    }
  }
  return <>{Level()}</>
}

Headline.propTypes = {
  className: PropTypes.string,
  level: PropTypes.number,
  id: PropTypes.string,
  children: PropTypes.any,
  html: PropTypes.string,
  ariaLabel: PropTypes.string,
}

export default Headline
