import React, { useState, useContext } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import tw from 'twin.macro'
import breakpoint from '@utils/breakpoint'
import PropTypes from 'prop-types'

import ToggleComponentContext from '@providers/toggleComponentProvider'

import SearchInput from '@objects/searchinput'
import Container from '@objects/container'

const TopContent = styled.div`
  ${tw`relative p-4 md:pb-10 flex flex-row justify-center items-end`}
  height: auto;

  ${breakpoint('md')`
    height: 300px;
  `}
`

const InfoBox = styled.div`
  ${tw`w-full md:w-1/3 p-2 flex flex-col`}
`

function SearchType({ index }) {
  const intl = useIntl()
  const { toggleOverlay, OverlayIsActive } = useContext(ToggleComponentContext)

  /**
   * a result is object with following properties
   * {
   *  id: Contentful Content-ID
   *  title: Title/Headline
   *  tags: Array of String - Which are the categories
   *  copy: The textcontent of the Copy field in Contentful
   *  path: The full path
   *  type: The content type (e.g. Aktion, News, Faq)
   * }
   */

  const [resultsAll, setResultsAll] = useState([])

  function search(searchquery) {
    setResultsAll(
      index
        .search(searchquery, {})
        .map(({ ref }) => index.documentStore.getDoc(ref))
        .filter((result) => result.locale === intl.locale)
    )
  }

  return (
    <>
      <TopContent>
        <Container nopadding width="md">
          <SearchInput
            searchfunc={search}
            resultsAll={resultsAll}
            onSuggestClick={() => toggleOverlay(false)}
            onKeyEnter={() => toggleOverlay(false)}
            isShown={OverlayIsActive}
          />
        </Container>
      </TopContent>
      <div className="flex flex-row flex-wrap justify-center items-center p-4">
        <div className="w-full md:w-8/12 lg:w-6/12 flex flex-row flex-wrap justify-center items-start">
          <h1
            className="w-full text-center py-4 md:pt-8 md:pb-10"
            role="heading"
            aria-label={intl.formatMessage({ id: 'search.overlay.info.hl' })}
          >
            <FormattedMessage id="search.overlay.info.hl" />
          </h1>
          <InfoBox role="paragraph">
            <strong>
              <FormattedMessage id="search.overlay.info.1" />
            </strong>
            <FormattedMessage id="search.overlay.copy.1" />
          </InfoBox>
          <InfoBox role="paragraph">
            <strong>
              <FormattedMessage id="search.overlay.info.2" />
            </strong>
            <FormattedMessage id="search.overlay.copy.2" />
          </InfoBox>
          <InfoBox role="paragraph">
            <strong>
              <FormattedMessage id="search.overlay.info.3" />
            </strong>
            <FormattedMessage id="search.overlay.copy.3" />
          </InfoBox>
        </div>
      </div>
    </>
  )
}

SearchType.propTypes = {
  index: PropTypes.object.isRequired,
}

export default SearchType
