import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import clsx from 'clsx'

import Icon from '@objects/icon'

const BackTopIcon = styled(Icon).attrs({
  name: 'BackTop',
  className: 'transform rotate-180 text-3xl',
})``

const BackTopButton = styled.button`
  opacity: ${(props) => props.visible};
  right: 50%;
  margin-right: -45%;
  transform: translateY(
    ${(props) => {
      return props.visible ? 0 : '50px'
    }}
  );

  @media screen and (min-width: 768px) {
    margin: initial;
    right: 20px;
  }
`

const StyledArrowForward = styled(Icon).attrs({
  name: 'TextArrow',
  className: 'text-xl text-red',
})`
  margin: -2px 3px 0 0;
`

const StyledShowMore = styled(Icon).attrs({
  name: 'ShowMore',
  className: 'text-xl',
})`
  margin-left: ${({ theme }) => theme.spacing['1']};
  margin-top: -3px;
`

/**
 *
 * @description You can set "to" for internal links, "href" for external links or "link",
 * when object should determine by the given string, if it is internal or external.
 * If link is set and link includes "www." it is considerd as external.
 * Otherwise if link is set on link does NOT include "www." it is considered as internal.
 */
function Button({
  className,
  children,
  hidden,
  to,
  href,
  link,
  onClick,
  backtop,
  textlink,
  showmore,
  primary,
  type,
  ...props
}) {
  const [isVisible, setVisibility] = useState(false)

  useEffect(() => {
    setVisibility(false)
    document.addEventListener('scroll', toggleVisibility)
    return () => {
      document.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  function toggleVisibility() {
    if (window.pageYOffset > 50) {
      setVisibility(true)
    } else {
      setVisibility(false)
    }
  }

  function scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  function renderButton() {
    const classes = clsx('btn', className, {
      'btn-text': textlink,
      'btn-large': primary,
      'btn-more': showmore,
    })
    if (hidden) {
      return
    } else if (to || (link && !link.includes('www.'))) {
      return (
        <Link
          className={classes}
          to={to || link}
          onClick={onClick}
          data-testid={'buttongatsbylink'}
          {...props}
        >
          {children}
          {textlink && <StyledArrowForward />}
        </Link>
      )
    } else if (href || (link && link.includes('www.'))) {
      return (
        <a
          className={classes}
          href={href || link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClick}
          data-testid={'buttonlink'}
          {...props}
        >
          {children}
          {textlink && <StyledArrowForward />}
        </a>
      )
    } else if (backtop) {
      return (
        <BackTopButton
          aria-label="nach oben scrollen"
          role="button"
          visible={isVisible ? 1 : 0}
          className="btn btn-backtop z-10"
          data-testid={'buttonbacktop'}
          {...props}
          onClick={scrollTop}
        >
          <BackTopIcon />
        </BackTopButton>
      )
    } else if (onClick) {
      return (
        <button
          className={classes}
          data-testid={'buttonclick'}
          onClick={onClick}
          {...props}
        >
          {children}
          {textlink && <StyledArrowForward />}
          {showmore && <StyledShowMore />}
        </button>
      )
    } else if (type) {
      if (type === 'label') {
        return (
          <label className={classes} data-testid={'buttonlabel'} {...props}>
            {children}
          </label>
        )
      } else {
        return (
          <button
            className={classes}
            data-testid={'buttonsubmit'}
            type={type}
            {...props}
          >
            {children}
          </button>
        )
      }
    } else {
      return (
        <span className={classes} data-testid={'buttondefault'} {...props}>
          {children}
          {textlink && <StyledArrowForward />}
        </span>
      )
    }
  }
  return <>{renderButton()}</>
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  backtop: PropTypes.bool,
  textlink: PropTypes.bool,
  showmore: PropTypes.bool,
  primary: PropTypes.bool,
  type: PropTypes.string,
}

export default Button
