import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'twin.macro'
import get from 'lodash/get'
import BulletIconSvg from '@static/img/icons/ul-bulletpoint.inline.svg'

import ListContent from '@objects/listcontent'

import { useTextParser, useGlossaryScope } from '@hooks'

const StyledUl = styled.ul`
  ${tw`
    pl-8.5
  `}
`

const StyledLi = styled.li`
  ${tw`
    mb-6
    relative
  `}
`

const BulletIcon = styled(BulletIconSvg)`
  top: 4px;
  ${tw`
    w-7
    h-4
    inline-block
    absolute
    -left-9
  `}
  circle,
  line {
    stroke: ${({ theme }) => get(theme, 'colors.yellow.default')};
  }
`

function UnorderedList({
  children,
  parseGlossary,
  glossaryExcludes,
  glossaryType,
}) {
  const { parseText, glossaryInitialized } = useTextParser()
  const { foundEntries, updateFoundEntries } = useGlossaryScope()
  const [parsedChildren, setParsedChildren] = useState(children)

  useEffect(() => {
    if (children && parseGlossary && glossaryInitialized) {
      setParsedChildren(
        children.map((item) => {
          const parsed = parseText(
            item,
            glossaryExcludes,
            glossaryType,
            foundEntries || []
          )
          updateFoundEntries && updateFoundEntries(parsed.foundEntries)
          return parsed.text
        })
      )
    }
  }, [glossaryInitialized])

  useEffect(() => {
    if (children && parseGlossary && glossaryInitialized) {
      setParsedChildren(
        children.map((item) => {
          const parsed = parseText(
            item,
            glossaryExcludes,
            glossaryType,
            foundEntries
          )
          return parsed.text
        })
      )
    }
  }, [foundEntries])

  return (
    <StyledUl role="list">
      {parsedChildren?.map((item, i) => {
        return (
          <StyledLi key={`${item?.created}${i}`} role="listitem">
            <BulletIcon className={'bullet-icon'} />
            <ListContent>{item}</ListContent>
          </StyledLi>
        )
      })}
    </StyledUl>
  )
}

UnorderedList.propTypes = {
  children: PropTypes.any.isRequired,
  parseGlossary: PropTypes.bool,
  glossaryExcludes: PropTypes.arrayOf(PropTypes.string),
  glossaryType: PropTypes.oneOf(['overlay', 'inline']),
}

UnorderedList.defaultProps = {
  glossaryType: 'overlay',
  glossaryExcludes: [],
}

export default UnorderedList
