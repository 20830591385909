import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Link } from 'gatsby'
import { useIntl, FormattedMessage } from 'react-intl'
import clsx from 'clsx'

import Icon from '@objects/icon'

const NavItem = styled(Link)`
  &:not(:first-of-type) {
    margin-left: ${({ theme }) => theme.spacing['7']};
  }
`

const StyledSubMenu = styled.div`
  max-height: 0;
  background: white;
  min-width: 100%;
  top: calc(22px);
  position: absolute;
  left: -20px;
  padding-top: 24px;

  &:before {
    content: '';
    top: -2px;
    left: 0;
    height: 5px;
    width: 100%;
    position: absolute;
    z-index: 10;
    background: white;
    display: block;
  }

  .submenu__inner {
    overflow: hidden;
    max-height: 0;
    ${tw`
      transition-all
      ease-linear
      duration-100
     `}
  }

  &.show-submenu {
    max-height: 400px;

    .submenu__inner {
      max-height: 400px;
    }
  }
  &.hide-submenu {
    padding-bottom: 0;
    padding-top: 0;
  }

  li:not(:last-child) {
    ${tw`mb-4 leading-12`}
  }
`
/* ToDo: calculate height */
const SubmenuMask = styled.div`
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;

  &:before,
  &:after {
    content: '';
    background: white;
    position: absolute;
    top: 0;
    left: -10px;
    height: 48px;
    width: 10px;
    display: block;
  }

  &:after {
    left: initial;
    right: -10px;
  }
`

function Navigation({ className, onClick }) {
  const intl = useIntl()
  const activeClass = 'text-red'
  const [parentHover, setParentHover] = useState({
    news: false,
  })
  function renderSubMenuIcon(open) {
    if (open) {
      return <Icon name="ToggleOpen" className={'close text-xl'} />
    } else {
      return <Icon name="ToggleClose" className={'open text-xl'} />
    }
  }
  return (
    <div
      className="flex flex-row flex-no-wrap items-center font-medium text-lg"
      data-testid="navigation"
    >
      <NavItem
        to={intl.formatMessage({ id: 'path.vision' })}
        onClick={onClick}
        activeClassName={activeClass}
      >
        <FormattedMessage id="navigation.vision" />
      </NavItem>
      <NavItem
        to={intl.formatMessage({ id: 'path.concept' })}
        onClick={onClick}
        activeClassName={activeClass}
      >
        <FormattedMessage id="navigation.concept" />
      </NavItem>
      <NavItem
        to={intl.formatMessage({ id: 'path.implementation' })}
        onClick={onClick}
        activeClassName={activeClass}
      >
        <FormattedMessage id="navigation.implementation" />
      </NavItem>
      <NavItem
        to={intl.formatMessage({ id: 'path.facts' })}
        onClick={onClick}
        activeClassName={activeClass}
      >
        <FormattedMessage id="navigation.facts" />
      </NavItem>
      <NavItem
        to={intl.formatMessage({ id: 'path.blog' })}
        onClick={onClick}
        activeClassName={activeClass}
      >
        <FormattedMessage id="navigation.blog" />
      </NavItem>
      <div
        className={'relative has-submenu ml-7'}
        onMouseEnter={() => setParentHover({ news: true })}
        onMouseLeave={() => setParentHover({ news: false })}
      >
        <NavItem
          to={intl.formatMessage({ id: 'path.media' })}
          onClick={onClick}
          activeClassName={activeClass}
          className="flex items-center"
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              setParentHover({ news: !parentHover['news'] })
              ev.preventDefault()
            }
          }}
        >
          <FormattedMessage id="navigation.newsroom" />
          {renderSubMenuIcon(parentHover.news)}
        </NavItem>
        <StyledSubMenu
          className={clsx(
            'absolute px-4 pb-4 transition-all ease-linear duration-100 rounded-bl-vsm rounded-br-vsm shadow z-50 submenu',
            {
              'show-submenu': parentHover['news'],
              'hide-submenu': !parentHover['news'],
            }
          )}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              setParentHover({ news: false })
            }
          }}
        >
          <SubmenuMask />
          <div className={'submenu__inner'}>
            <ul role="list">
              <li role="listitem">
                <NavItem
                  to={intl.formatMessage({ id: 'path.media' })}
                  tabIndex={parentHover['news'] ? 0 : -1}
                >
                  <FormattedMessage id={'navigation.newsroom.media'} />
                </NavItem>
              </li>
              <li role="listitem">
                <NavItem
                  to={intl.formatMessage({ id: 'path.downloads' })}
                  tabIndex={parentHover['news'] ? 0 : -1}
                >
                  <FormattedMessage id={'navigation.newsroom.downloads'} />
                </NavItem>
              </li>

              <li role="listitem">
                <NavItem
                  to={intl.formatMessage({ id: 'path.faq' })}
                  tabIndex={parentHover['news'] ? 0 : -1}
                >
                  <FormattedMessage id={'navigation.newsroom.faq'} />
                </NavItem>
              </li>
              <li role="listitem">
                <NavItem
                  to={intl.formatMessage({ id: 'glossary.path' })}
                  tabIndex={parentHover['news'] ? 0 : -1}
                >
                  <FormattedMessage id={'glossary.headline'} />
                </NavItem>
              </li>
              <li role="listitem">
                <NavItem
                  to={intl.formatMessage({ id: 'path.newsroom' })}
                  tabIndex={parentHover['news'] ? 0 : -1}
                >
                  <FormattedMessage id={'navigation.newsroom.current'} />
                </NavItem>
              </li>
            </ul>
          </div>
        </StyledSubMenu>
      </div>
    </div>
  )
}

Navigation.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  open: PropTypes.bool,
}

export default Navigation
