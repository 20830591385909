import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

function Divider({ className }) {
  return <div className={className} data-testid="divider" />
}

Divider.propTypes = {
  className: PropTypes.string,
}

export default styled(Divider).attrs({
  className: 'bg-gray-light-300',
})`
  height: 6px;
  margin-bottom: 6px;
`
