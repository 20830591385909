const plugin = require('tailwindcss/plugin')

module.exports = plugin(function ({ addBase, config }) {
	addBase({
		a: {
			transitionProperty: config('theme.transitionProperty.all'),
			transitionDuration: config('theme.transitionDuration.200'),
			'&:hover': {
				color: config('theme.colors.red.default'),
				textDecoration: 'underline',
			},
			'&:focus': {
				outline: '3px solid #008000',
			},
		},
	})
})
