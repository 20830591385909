import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Copy from '@objects/copy'
import Icon from '@objects/icon'
import ToggleComponentContext from '@providers/toggleComponentProvider'

const StyledBackdrop = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.black[650]};
  opacity: 0.65;
`

function Popup({ copy, buttonlabel, onAcceptance }) {
  const { toggleOverlay } = useContext(ToggleComponentContext)

  function onClose() {
    onAcceptance()
    toggleOverlay(false)
  }

  return (
    <>
      <StyledBackdrop
        className="absolute top-0 bottom-0 left-0 right-0 "
        onClick={onClose}
        role="dialog"
      />
      <div
        className={'bg-white p-8 relative max-w-391 rounded-vsm'}
        aria-live="polite"
      >
        <button
          data-testid="close-button"
          className={'absolute top-2 right-2 w-5 h-5'}
          onClick={onClose}
          role="button"
        >
          <Icon className="text-xl align-top" name="Close" />
        </button>
        <Copy html={copy} />
      </div>
    </>
  )
}

Popup.propTypes = {
  copy: PropTypes.string,
  buttonlabel: PropTypes.string,
  onAcceptance: PropTypes.func,
}

export default Popup
