import React from 'react'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'
import clsx from 'clsx'

import Copy from '@objects/copy'
import Divider from '@objects/divider'
import Container from '@objects/container'
import LogoGrid from '@objects/logoGrid'
import styled from 'styled-components'

const FooterContainer = styled(Container).attrs({
  className: 'space-y-8',
})``

const Row = styled.div.attrs({
  className: 'flex items-center justify-between flex-col-reverse md:flex-row',
})``

const Right = styled.div.attrs({
  className: 'flex space-y-4 md:space-y-0 md:space-x-7',
})``

const Left = styled.div``

const Copyright = styled(Copy).attrs({
  className: 'text-base leading-3 font-normal',
})``

const NavItem = styled(Link).attrs({
  className: 'text-lg leading-12 font-medium hover:text-red-on-gray',
})``

function Footer({ className }) {
  const intl = useIntl()

  return (
    <div className="">
      <Divider />
      <Divider />
      <footer
        className={clsx(className, 'bg-gray-light-300 pt-9 pb-10 md:pb-12')}
        role="contentinfo"
        aria-label="Footer"
      >
        <FooterContainer nopadding>
          <Row>
            <Left>
              <img src="/img/logo/dt_logo.svg" alt="Deutschlandtakt" />
            </Left>
            <Right />
          </Row>
          <Row>
            <Left>
              <Copyright
                html={intl.formatMessage({ id: 'footer.copyright' })}
                aria-label={intl.formatMessage({ id: 'footer.copyright' })}
                role="paragraph"
              />
            </Left>
            <Right
              className="flex-col items-center md:flex-row mb-9 md:mb-none"
              role="group"
              aria-label="Footer Navigation"
            >
              <NavItem to={intl.formatMessage({ id: 'path.easyLanguage' })}>
                <FormattedMessage id="footer.easyLanguage" />
              </NavItem>
              <NavItem to={intl.formatMessage({ id: 'path.accessiblity' })}>
                <FormattedMessage id="footer.accessiblity" />
              </NavItem>
              <NavItem to={'/kontakt/'}>
                <FormattedMessage id="footer.contact" />
              </NavItem>
              <NavItem to={'/datenschutzerklaerung/'}>
                <FormattedMessage id="footer.dataprivacy" />
              </NavItem>
              <NavItem to={'/impressum/'}>
                <FormattedMessage id="footer.imprint" />
              </NavItem>
            </Right>
          </Row>

          <LogoGrid />
        </FooterContainer>
      </footer>
    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
