import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'twin.macro'

import get from 'lodash/get'
import ListContent from '@objects/listcontent'

import { useTextParser, useGlossaryScope } from '@hooks'

const StyledOl = styled.ol.attrs({
  className: 'pl-8.5',
})``

const StyledLi = styled.li`
  ${tw`
    mb-6
    relative
  `}
`

const BulletNumber = styled.div`
  border: 3px solid ${({ theme }) => get(theme, 'colors.yellow.default')};
  text-align: center;
  vertical-align: middle;
  line-height: 28px;
  font-size: 15px;
  top: -3px;
  padding-left: 1px;
  ${tw`
    rounded-full
    w-7
    h-7
    inline-block
    absolute
    -left-9
    table-cell
    font-bold
  `}
`

function OrderedList({
  children,
  parseGlossary,
  glossaryExcludes,
  glossaryType,
}) {
  const { parseText, glossaryInitialized } = useTextParser()
  const { foundEntries, updateFoundEntries } = useGlossaryScope()
  const [parsedChildren, setParsedChildren] = useState(children)

  useEffect(() => {
    if (children && parseGlossary && glossaryInitialized) {
      setParsedChildren(
        children.map((item) => {
          const parsed = parseText(
            item,
            glossaryExcludes,
            glossaryType,
            foundEntries || []
          )
          updateFoundEntries && updateFoundEntries(parsed.foundEntries)
          return parsed.text
        })
      )
    }
  }, [glossaryInitialized])

  useEffect(() => {
    if (children && parseGlossary && glossaryInitialized) {
      setParsedChildren(
        children.map((item) => {
          const parsed = parseText(
            item,
            glossaryExcludes,
            glossaryType,
            foundEntries
          )
          return parsed.text
        })
      )
    }
  }, [foundEntries])

  return (
    <StyledOl role="list">
      {parsedChildren?.map((item, i) => {
        return (
          <StyledLi key={`${item?.created}${i}`} role="listitem">
            <BulletNumber>{i + 1}</BulletNumber>

            <ListContent>{item}</ListContent>
          </StyledLi>
        )
      })}
    </StyledOl>
  )
}

OrderedList.propTypes = {
  children: PropTypes.any,
  parseGlossary: PropTypes.bool,
  glossaryExcludes: PropTypes.arrayOf(PropTypes.string),
  glossaryType: PropTypes.oneOf(['overlay', 'inline']),
}

OrderedList.defaultProps = {
  style: 'default',
  glossaryType: 'overlay',
}

export default OrderedList
