import { useContext } from 'react'

import TextParserContext from '@providers/textParserProvider'

export default function useTextParser() {
  const { parseText, setGlossaryData, glossaryInitialized } = useContext(
    TextParserContext
  )

  return { parseText, setGlossaryData, glossaryInitialized }
}
