const plugin = require('tailwindcss/plugin')

module.exports = plugin(function ({ addBase, addComponents, config }) {
  const theme = config('theme')

  addBase({
    h1: {
      fontSize: theme.fontSize['4xl'],
      lineHeight: theme.lineHeight['11'],
      fontWeight: 300,
    },
    h2: {
      fontSize: theme.fontSize['3xl'],
      lineHeight: theme.lineHeight['9'],
      fontWeight: 300,
    },
    h3: {
      fontSize: theme.fontSize['xl'],
      lineHeight: theme.lineHeight['8'],
      fontWeight: 500,
    },
    h4: {
      fontSize: theme.fontSize['2lg'],
      lineHeight: theme.lineHeight['5'],
      fontWeight: 400,
    },
    h5: {
      fontSize: theme.fontSize.lg,
      lineHeight: theme.lineHeight['4'],
      fontWeight: 500,
    },
  })

  const headlineMedia = (
    level,
    width,
    fontSize,
    lineHeight,
    fontWeight = null
  ) => {
    return {
      [`@media (min-width: ${width})`]: {
        [`h${level}`]: {
          fontSize,
          lineHeight,
          fontWeight,
        },
      },
    }
  }

  addComponents([
    headlineMedia(
      1,
      theme.screens.md,
      theme.fontSize['6xl'],
      theme.lineHeight['13']
    ),
    headlineMedia(
      2,
      theme.screens.md,
      theme.fontSize['5xl'],
      theme.lineHeight['12']
    ),
    headlineMedia(
      3,
      theme.screens.md,
      theme.fontSize['3xl'],
      theme.lineHeight['9']
    ),
    headlineMedia(
      4,
      theme.screens.md,
      theme.fontSize['xl'],
      theme.lineHeight['7']
    ),
  ])
})
