import React, { useState } from 'react'
import PropTypes from 'prop-types'

import GlossaryLink from '@objects/glossaryLink'

const TextParserContext = React.createContext()

export function TextParserProvider({ children }) {
  const [glossaryData, setGlossaryData] = useState({})
  const [glossaryInitialized, setGlossaryInitialized] = useState(false)

  function parseText(text, excludes, type, found) {
    let foundEntries = found || []
    let parsedText = null
    let reg = new RegExp(
      '\\b(' + Object.keys(glossaryData).join('|') + ')\\b',
      'gi'
    )

    if (typeof text === 'string') {
      parsedText = text.split(reg).map((part, i) => {
        if (
          glossaryData[part] &&
          !excludes?.includes(part) &&
          !foundEntries?.includes(part)
        ) {
          foundEntries.push(part)

          return (
            <GlossaryLink
              key={i}
              word={glossaryData[part].id}
              title={glossaryData[part].title}
              description={glossaryData[part].description}
              synonyms={glossaryData[part].synonyms}
              type={type || 'overlay'}
            >
              {part}
            </GlossaryLink>
          )
        } else {
          return part
        }
      })
    } else {
      parsedText = text.map((item) => {
        if (typeof item === 'string') {
          return item.split(reg).map((part, i) => {
            if (
              glossaryData[part] &&
              !excludes?.includes(part) &&
              !foundEntries?.includes(part)
            ) {
              foundEntries.push(part)
              return (
                <GlossaryLink
                  key={i}
                  word={glossaryData[part].id}
                  description={glossaryData[part].description}
                  title={glossaryData[part].title}
                  synonyms={glossaryData[part].synonyms}
                  type={type || 'overlay'}
                >
                  {part}
                </GlossaryLink>
              )
            } else {
              return part
            }
          })
        } else {
          return item
        }
      })
    }

    return {
      text: parsedText,
      foundEntries: foundEntries,
    }
  }

  function updateGlossaryData(data) {
    const newData = {}
    for (let i = 0; i < data.length; i++) {
      if (data[i].synonyms) {
        for (let j = 0; j < data[i].synonyms.length; j++) {
          newData[data[i].synonyms[j]] = {
            id: data[i].synonyms[0].replace(/\s+/g, ''),
            title: data[i].title,
            description: data[i].description.childMarkdownRemark.html,
            synonyms: data[i].synonyms,
          }
        }
      }
    }
    setGlossaryData(newData)
    setGlossaryInitialized(true)
  }

  return (
    <TextParserContext.Provider
      value={{ parseText, updateGlossaryData, glossaryInitialized }}
    >
      {children}
    </TextParserContext.Provider>
  )
}

TextParserProvider.propTypes = {
  children: PropTypes.any,
}

export default TextParserContext
