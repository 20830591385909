import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ToggleCloseIcon from '@material-ui/icons/KeyboardArrowDown'
import ToggleOpenIcon from '@material-ui/icons/KeyboardArrowUp'
import SearchIcon from '@material-ui/icons/SearchRounded'
import ArrowForward from '@material-ui/icons/ArrowForwardRounded'
import ArrowDownward from '@material-ui/icons/ArrowDownwardRounded'
import Event from '@material-ui/icons/Event'
import PlaceIcon from '@material-ui/icons/PlaceOutlined'
import Close from '@material-ui/icons/Close'
import SaveAlt from '@material-ui/icons/SaveAltRounded'
import PermMedia from '@material-ui/icons/PermMediaOutlined'
import ChevronLeft from '@material-ui/icons/ArrowBackIosRounded'
import ChevronRight from '@material-ui/icons/ArrowForwardIosRounded'
import GetApp from '@material-ui/icons/GetAppRounded'
import Home from '@material-ui/icons/HomeRounded'
import AccordionOpen from '@material-ui/icons/Add'
import AccordionClose from '@material-ui/icons/Remove'
import Description from '@material-ui/icons/DescriptionOutlined'
import FAQ from '@material-ui/icons/ChatBubbleOutline'
import PictureAsPdf from '@material-ui/icons/PictureAsPdfOutlined'
import PlayCircle from '@material-ui/icons/PlayCircleFilledRounded'
import PauseCircle from '@material-ui/icons/PauseCircleFilledRounded'
import Info from '@material-ui/icons/InfoOutlined'
import Checked from '@material-ui/icons/CheckRounded'
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined'
import AddCircleRounded from '@material-ui/icons/AddCircleRounded'
import RemoveCircleRounded from '@material-ui/icons/RemoveCircleRounded'
import Tune from '@material-ui/icons/Tune'
import DeveloperMode from '@material-ui/icons/DeveloperMode'

function Icon({ className, name }) {
  function renderIcon() {
    switch (name) {
      case 'Search':
        return <SearchIcon className={className} />
      case 'ToggleClose':
        return <ToggleCloseIcon className={className} />
      case 'ToggleOpen':
        return <ToggleOpenIcon className={className} />
      case 'TextArrow':
        return <ArrowForward className={className} />
      case 'ShowMore':
        return <ArrowDownward className={className} />
      case 'Event':
        return <Event className={className} />
      case 'Location':
        return <PlaceIcon className={className} />
      case 'Close':
        return <Close className={className} />
      case 'FAQ':
        return <FAQ className={className} />
      case 'Download':
        return <SaveAlt className={className} />
      case 'Media':
        return <PermMedia className={className} />
      case 'Prev':
        return <ChevronLeft className={className} />
      case 'Next':
        return <ChevronRight className={className} />
      case 'BackTop':
        return <GetApp className={className} />
      case 'Home':
        return <Home className={className} />
      case 'AccordionOpen':
        return <AccordionOpen className={className} />
      case 'AccordionClose':
        return <AccordionClose className={className} />
      case 'Description':
        return <Description className={className} />
      case 'PDF':
        return <PictureAsPdf className={className} />
      case 'Play':
        return <PlayCircle className={className} />
      case 'Pause':
        return <PauseCircle className={className} />
      case 'Info':
        return <Info className={className} />
      case 'Checked':
        return <Checked className={className} />
      case 'Press':
        return <AssignmentIndOutlinedIcon className={className} />
      case 'AddCircleRounded':
        return <AddCircleRounded className={className} />
      case 'RemoveCircleRounded':
        return <RemoveCircleRounded className={className} />
      case 'Tune':
        return <Tune className={className} />
      case 'DeveloperMode':
        return <DeveloperMode className={className} />

      default:
        return null
    }
  }
  return renderIcon()
}

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOf([
    'Search',
    'ToggleClose',
    'ToggleOpen',
    'TextArrow',
    'ShowMore',
    'Event',
    'Location',
    'Close',
    'FAQ',
    'Download',
    'Media',
    'Prev',
    'Next',
    'BackTop',
    'Home',
    'AccordionOpen',
    'AccordionClose',
    'Description',
    'PDF',
    'Play',
    'Pause',
    'Info',
    'Checked',
    'Press',
    'AddCircleRounded',
    'RemoveCircleRounded',
    'Tune',
    'DeveloperMode',
  ]),
}

export default styled(Icon)`
  width: 1em;
  height: 1em;
  color: currentColor;
`
