const plugin = require('tailwindcss/plugin')

module.exports = plugin(function ({ addUtilities, config }) {
  addUtilities(
    {
      '.pin-c-x': {
        left: '50%',
        transform: 'translateX(-50%)',
      },
      '.pin-c-y': {
        top: '50%',
        transform: 'translateY(-50%)',
      },
      '.pin-c': {
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
      },
    },
    ['responsive']
  )
})
