const plugin = require('tailwindcss/plugin')

module.exports = plugin(function ({ addBase, addComponents, config }) {
  const theme = config('theme')
  addBase({
    body: {
      fontFamily: theme.fontFamily['lt'],
      fontWeight: 300,
      fontSize: theme.fontSize.baseXS,
      lineHeight: theme.lineHeight.baseXS,
      color: theme.colors.black.default,
    },
    strong: {
      fontWeight: 700,
    },
    b: {
      fontWeight: 700,
    },
    svg: {
      maxWidth: '100%',
      width: '1em',
      height: '1em',
      color: 'currentColor',
    },
  })

  addComponents([
    {
      [`@media (min-width: ${theme.screens.md})`]: {
        body: {
          fontSize: theme.fontSize.base,
          lineHeight: theme.lineHeight['7'],
        },
      },
    },
  ])
})
