const Theme = require('./config/theme/index')
const Variants = require('./config/variants')
const AnchorLinks = require('./config/base/anchorlinks')
const Headlines = require('./config/base/headlines')
const htmlbody = require('./config/base/htmlbody')
const Buttons = require('./config/components/button')
const Pin = require('./config/utils/pin')
const Tracklines = require('./config/utils/tracklines')

module.exports = {
  target: ['relaxed'],
  purge: ['./src/**/*.jsx'],
  theme: Theme,
  variants: {
    extend: {
      scale: ['group-hover'],
    },
  },
  plugins: [
    Variants,
    htmlbody,
    AnchorLinks,
    Headlines,
    Pin,
    Buttons,
    Tracklines,
  ],
}
